.project-zone {
    margin: 50px 0;
    position: relative;
    width: 100%;
    max-width: 1300px;
    margin-left: 0;

    margin-right: auto;
}

.carousel-project {
    position: relative;
    text-align: center;
    overflow: hidden;
    width: 1300px;
    height: 700px;
    margin-left: 50px;
}

.carousel-image {
    width: 100%; /* Устанавливаем ширину изображения по ширине контейнера */
    max-width: 100%; /* Ограничиваем ширину контейнера */
    height: 500px; /* Фиксированная высота для всех изображений */
    object-fit: cover; /* Обрезаем изображение, чтобы заполнить контейнер */
    transition: opacity 0.5s ease-in-out;
}

.carousel-controls {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
    z-index: 1000;
}

.carousel-controls .prev,
.carousel-controls .next {
    background: rgba(0, 0, 0, 0.5);
    border: none;
    font-size: 2rem;
    color: #fff;
    padding: 10px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.carousel-controls .prev:hover,
.carousel-controls .next:hover {
    background: rgba(0, 0, 0, 0.7);
}

.carousel-caption-project {
    max-width: 1320px;
    position: absolute;
    bottom: 25px;
    left: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
}

.caption-text {
    text-align: left;
}

.caption-text p {
    font-size: 19px;
    width: 80%;

}

.caption-text h3 {
    font-size: 45px;
}

.project-book {
    margin-left: 300px;
    text-decoration: none;
    color: white;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 13px 43px;
    font-size: 20px;
    font-weight: 500;
    transition: background 0.3s ease, color 0.3s ease;
}

.project-book:hover {
    background: white;
    color: black;
}
.podcast-text{
    text-align: center;
    justify-content: center;
    display: flex;


}
/* For small screens like mobile devices */
@media (max-width: 768px) {
    .project-zone {
        margin: 20px 0;
        width: 100%; /* Ensure it takes up full width on mobile */
        max-width: 100%; /* Remove the max-width constraint */
        margin-left: 0;
        margin-right: 0;
    }

    .carousel-project {
        width: 100%; /* Make the carousel take up full width */
        height: auto; /* Allow the height to be adaptive */
        margin-left: 0;
        overflow: hidden;
        position: relative;/* Center align on small screens */
    }

    .carousel-image {
        height: auto; /* Let the image height adapt to the content */
        max-width: 100%; /* Ensure the image fills the container */
    }

    .carousel-controls {
        padding: 0 20px; /* Add padding to ensure controls are visible on small screens */
    }

    .carousel-caption-project {
        flex-direction: column; /* Stack the caption text and button vertically */
        align-items: flex-start; /* Align items to the start */
        bottom: 10px; /* Adjust position for smaller screens */
        left: 10px; /* Adjust position for smaller screens */
    }

    .caption-text h3 {
        font-size: 24px; /* Reduce font size for better readability */
    }

    .caption-text p {
        font-size: 16px; /* Reduce font size for better readability */
        width: 100%; /* Make sure the text takes up full width */
    }

    .project-book {
        margin-right: 0;
        margin-top: 10px; /* Add spacing on top for the button */
        padding: 10px 20px; /* Adjust padding for smaller screens */
        font-size: 16px; /* Reduce font size */
    }

    .podcast-text {
        flex-direction: column; /* Stack elements vertically */
        align-items: center; /* Center align the elements */
        text-align: center; /* Center align the text */
    }
}

/* For medium screens like tablets */
@media (max-width: 1024px) {
    .project-zone {
        margin: 30px 0;
        width: 100%; /* Ensure it takes up full width on tablets */
        max-width: 100%; /* Remove the max-width constraint */
        margin-left: 0;
        margin-right: 0;
    }

    .carousel-project {
        width: 100%; /* Make the carousel take up full width */
        height: 500px; /* Set a fixed height suitable for tablets */
        margin-left: 0;
        overflow: hidden;
        position: relative;/* /* Center align on tablets */
    }

    .carousel-image {
        height: auto; /* Let the image height adapt to the content */
        max-width: 100%; /* Ensure the image fills the container */
    }

    .carousel-caption-project {
        flex-direction: row; /* Keep the caption text and button in a row */
        align-items: center; /* Align items to the center */
        bottom: 15px; /* Adjust position for tablets */
        left: 10px;
        top: 300px;
         /* Adjust position for tablets */
    }

    .caption-text h3 {
        font-size: 32px; /* Adjust font size for tablets */
    }

    .caption-text p {
        font-size: 18px; /* Adjust font size for tablets */
        width: 90%; /* Ensure the text fits well on tablets */
    }

    .project-book {
        margin-left: 800px; /* Add some margin to the right */
         /* Keep the button in line with the text */
        padding: 12px 30px; /* Adjust padding for tablets */
        font-size: 18px;
        margin-bottom: 100px;
        display: none; /* Adjust font size */
    }

    .podcast-text {
        flex-direction: row; /* Keep elements in a row on tablets */
        align-items: center; /* Align items to the center */
        text-align: left; /* Left align the text */
    }
}
@media (min-width: 2500px) {
    *{
        font-family: "Montserrat", sans-serif;
    }
    .project-zone {
        margin: 80px 0;
        margin-top: 200px;
        max-width: 100%;
        margin-left: 50px;
        font-family: "Montserrat", sans-serif;
    }

    .carousel-project {
        width: 80%;
        height: 900px;
        margin-left: 100px;
    }

    .carousel-image {
        height: 900px;
    }

    .carousel-controls {
        top: 45%;
        width: 100%;
    }

    .carousel-controls .prev,
    .carousel-controls .next {
        font-size: 3rem;
        padding: 20px;
    }

    .carousel-caption-project {
        max-width: 100%;
        bottom: 40px;
        left: 40px;
        font-family: "Montserrat", sans-serif;
    }

    .caption-text p {
        font-size: 26px;
        width: 70%;
    }

    .caption-text h3 {
        font-size: 60px;
    }

    .project-book {
        margin-left: 500px;
        padding: 20px 60px;
        font-size: 26px;
    }

    .podcast-text {
        justify-content: center;
        text-align: center;
        font-size: 28px;
    }
}
@media (min-width: 3840px) {
    *{
        font-family: "Montserrat", sans-serif;
    }
    .project-zone {
        margin: 120px 0;
        margin-top: 300px;
        max-width: 100%;
        margin-left: 80px;
    }

    .carousel-project {
        width: 80%;
        height: 1500px;
        margin-left: 150px;
    }

    .carousel-image {
        height: 1200px;
    }

    .carousel-controls {
        top: 45%;
        width: 100%;
    }

    .carousel-controls .prev,
    .carousel-controls .next {
        font-size: 4rem;
        padding: 30px;
    }

    .carousel-caption-project {
        max-width: 100%;
        bottom: 60px;
        left: 60px;
    }

    .caption-text p {
        font-size: 50px;
        width: 80%;
    }

    .caption-text h3 {
        font-size: 120px;
    }

    .project-book {
        margin-left: 800px;
        padding: 30px 80px;
        font-size: 50px;
        margin-top: 300px;
    }

    .podcast-text {
        justify-content: center;
        text-align: center;
        font-size: 36px;
    }
}
