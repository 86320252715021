


.team-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 70px;
    gap: 20px;


}
.header{
    display:flex;

}
body{
    background-color: black;
    color: white;
    max-width: 100%;
    font-family: "Montserrat", sans-serif;
}

nav ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: 80px;

    list-style-type: none;
    padding: 0;
    margin-top: 120px;


}

nav ul li {
    width: 50%; /* Each link takes up half the width of the container */
    box-sizing: border-box;

     /* Ensures padding and border are included in the width */
}

nav ul li a {
    display: block;
    width: 70px;

    text-align: left;
    text-decoration: none;
    color: white;
    font-size: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;



}
nav ul li a.active {
    color: red; /* Выделяем активную ссылку красным цветом */
    font-weight: bold; /* Можно добавить другие стили */
  }
.header_text{
    font-size: 15px;
    color:  #898585;
    width: 370px;
    margin-left: 80px;
    margin-top: 100px;

}
.header_text1{
    font-size: 15px;
    color:  #898585;
    width: 370px;
    margin-left: 80px;
    margin-top: 15px;

}
.header_text2{
    font-size: 13px;
    color:  #898585;
    width: 370px;

    margin-top: 140px;

}
.header_text3{
    font-size: 13px;
    color:  #898585;
    width: 370px;

    margin-top: 15px;

}
.header_text4{
    font-size: 16px;
    color:  #898585;
    width: 370px;
    margin-left: 80px;

    margin-top: 40px;

}
.header_text5{
    font-size: 16px;
    color:  #898585;
    width: 370px;
    margin-left: 80px;

    margin-top: 15px;

}
.header-content {

    overflow-x: hidden;

}
.media-studio {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    text-align: center;
    font-size: 150px;
    width: 800px;
    margin-top: 75px;
    margin-left: 20px;
    font-weight: bold;
}
.background-image {
    position: absolute;
    margin-left: 530px;
    margin-top: 260px;
    width: 470px;
    height: 380px; /* Adjust height as needed */
    background-image: url('./assets/studio.jpg');
    background-size: cover;
    background-position: center;
    z-index: -1; /* Place image behind other content */
}

.media {
    text-align: left;
}
.media, .studio {
    margin: 0; /* Remove default margins */
    padding: 0; /* Remove default padding */
    line-height: 1; /* Adjust line-height if necessary */
}
.studio {
    text-align: right;
}
/**/
.logo{
    width: 81px;
    height: 65px;
    margin-left: 74px;
    margin-top: 75px;
}
.reserve{
    margin-left: 600px;
    margin-top: 215px;
    font-size: 20px;
    background-color: none;

}
.faq_container{
    width: 90%; /* or any fixed width */
    margin: 0 auto;

}
.faq{
max-width: 1300px;
margin-top: 2rem;
padding-bottom: 12px;
border-bottom: 2px solid #fff;
cursor: pointer;
}



.question_2{
    display: flex;
    align-items: center;
    max-width: 500px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 1.4s ease;
}
.question{
display: flex;
justify-content: space-between;
align-items: center;

}

.question h3{
font-size: 1.8rem;
}

.answer {
    max-height: 0;

    transition: max-height 1.4s ease;
}

.faq-button{

    padding: 20px 0;
    overflow: hidden;
    text-decoration: none;
    opacity: 0;
    transition: opacity 1.4s ease;
    color: white;
    border: 1px solid #fff;
    border-radius: 10px;

    padding: 15px 10px;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
}


.faq.active .answer{
max-height: 300px;
}
.faq.active .faq-button{
    max-height: 300px;
    display: block;
    opacity: 1;
}
.faq.active .question_2{
    max-height: 300px;
    }

.answer p {
    padding-top: 1rem;
    line-height: 1.6;
font-size: 1.4rem;
}

.faq.active svg{
    transform: rotate(180deg);
}
.faq.active .question{
    padding-bottom: 30px;
}

svg{
transition:  transform .5s ease;
}
.line{
    border-top: 2px solid white;
    width: 300px;

}
.text{
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.about{
    display: flex;
    margin-top: 100px;
    margin-left: 80px;


}
.stat{
    display: flex;
    justify-content: space-between;
    width: 850px;
    height: 150px;
}
.stats{
    width: 850px;
    margin-left: 90px;

}
.about h2{
 font-size: 70px;
}
.number{
    font-size: 70px;
    font-weight: 600;
    margin-top: 60px;
}
.stat_text{
    font-size: 20px;
    font-weight: 600;
    margin-top: 100px;

}
.line1{
    border-top: 1.5px solid white;
    width: 100%;
    margin-top: 0;

}
.podcast-zone {
    margin: 50px 0;
    position: relative;

}
/**/
.carousel {
    position: relative;
    text-align: center;
}

.carousel img {
    max-width: 100%;
    height: auto;
    padding: 0 80px;
    overflow-y: hidden;

}

.carousel-controls {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
}
.carousel-controls .prev{
    margin-left: 100px;
}
.carousel-controls .prev,
.carousel-controls .next {

    background: transparent;
    border: none;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
}
.carousel-controls .next{
    margin-right: 100px;
}
.carousel-caption {
    position: absolute;
    bottom: 10px;
    left: 20px;
    color: #fff;
    justify-content: space-between;
    display: flex;
    align-items: center;

}
.carousel-caption h3{
    margin-left: 200px;
    font-size: 40px;
}
.carousel-caption-project h3{
    margin-left: 200px;
    font-size: 40px;
}
.project-book {
    text-decoration: none;
    color: white;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 7px 14px;
    font-size: 18px;
    font-weight: 500;
    margin-right: 100px;

}
.podcast-book{
    margin-left: 500px;
    text-decoration: none;
    color: white;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 7px 14px;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
    height: 50px;


}

.services{
    margin-top: 80px;
}
.service-header{
    display: flex;
    align-items: center;
    margin-left: 80px;
    justify-content: space-between;
    margin-right: 80px;

}
.service-header h2{
    font-size: 70px;
}
.service-book{
    text-decoration: none;
    color: white;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 15px 30px;
    font-size: 18px;
    font-weight: 500;
}

.projects h2{
    font-size: 70px;
    margin-left: 70px;

}
.project-zone {
    margin: 50px 0;
    position: relative;
}


.carousel-project {
    position: relative;
    text-align: center;
}

.carousel-project img {
    width: 1300px;
    height: auto;
    padding: 0 80px;
    overflow-y: hidden;

}

.carousel-controls {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
}
.carousel-controls .prev{
    margin-left: 100px;
}
.carousel-controls .prev,
.carousel-controls .next {

    background: transparent;
    border: none;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
}
.carousel-controls .next{
    margin-right: 100px;
}
.carousel-caption-project {
    max-width: 1320px;
    position: absolute;
    bottom: 25px;
    left: 20px;
    color: #fff;
    justify-content: space-between;
    display: flex;
    align-items: center;

}
/**/
.carousel-caption-project h3{
    margin-left: 130px;
}
.caption-text{
    text-align: left;


}
.podcast-text{
    display: flex;
    justify-content: space-between;



}
.podcast{
    position: absolute;
    bottom: 10px;
    left: 20px;
    color: #fff;
    justify-content: space-between;
    display: flex;
    align-items: center;
}
.podcast-text h3{

        margin-left: 200px;
        font-size: 40px;

}
.caption-text p{
    font-size: 19px;
    margin-left: 130px;
}
.caption-text h3{
    font-size: 45px;
}
.project-book{
    margin-left: 200px;
    text-decoration: none;
    color: white;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 13px 43px;
    font-size: 20px;
    font-weight: 500;
    margin-top: 100px;


}
.podcast-book{
    margin-left: 500px;
    text-decoration: none;
    color: white;
    border: 1px solid #fff;
    border-radius: 10px;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 500;
}
.team-book{
    text-decoration: none;
    color: white;


    font-size: 30px;
    font-weight: 600;
    font-style: italic;
}


  .team-member {
    flex-basis: calc(33.333% - 20px);
    text-align: center;
    margin-bottom: 20px;
    width: 400px;

  }

  .circular_image {
    width: 300px;
    height: 300px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 50%;
  }

  .circular_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .team-member h1 {
    font-size: 30px;
    margin: 20px 0 5px;
  }

  .team-member p {
    font-size: 20px;
    color: #898585;
  }

.contact h2{
    font-size: 65px;
    margin-left: 80px;
}
.contact-row{
    display: flex;
    justify-content: space-between;
}
form input{
    text-decoration: none;

    outline: none;
}
.contact-section {
    padding: 50px 0;
    text-align: center;
}

.container {
        display: flex;
        justify-content: space-between;
    max-width: 1200px;
    margin-left: 80px;

    padding: 30px;

}

.form-container {
    flex: 2;
    text-align: left;

}

.info-container {
    flex: 1;
    padding-left: 30px;
    text-align: left;
    margin-left: 50px;


}
.info-container .contact-info a {
    display: block;
    margin: 0px 0;
    padding: 0;
}

/**/
.info-container a{
    text-decoration: none;
    color: white;
}
.info-container br{
 height: 0;
}

form {

    flex-direction: column;

}

.form-group {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}
.form-group input{
    color: #fff;
}

label {
    margin-bottom: 0px;
    color: #555;
    font-size: 18px;
}

input, textarea {
    width: 600px;

    border: none;
    border-bottom: 1px solid #ccc;
    font-size: 16px;
    outline: none;
    background: none;
}



textarea {
    resize: none;
}

button {


    color:white;
    border: none;

    cursor: pointer;
    font-size: 20px;
    align-self: flex-start;
    background-color: black;
    margin-top: 40px;
    border: 1px solid white;
    border-radius: 10px;
    padding: 7px 14px;

}
.contact-info h2{
    font-size: 20px;
    color: #555;
}
.contact-info{
    text-align: right;
    margin-top: 90px;
    margin-left: 570px;
}
.highlight{
    color: red;
    font-weight: 600;
}
.footer-row{
    display: flex;
    justify-content: space-between;
    margin-left: 80px;
    margin-right: 80px;


}
.footer-row div{
    font-size: 50px;
    font-weight: 500;
    width: 900px;
    margin-top: 100px;
}
.footer-row ul li a, li {
    text-decoration: none;
    list-style: none;
    font-size: 20px;
    padding-top: 20px;
    color: #fff;
    font-weight: 600;
    text-align: right;

}
.under-row{
    display: flex;
    justify-content: space-between;
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 150px;
}

.idea{
font-size: 15px;
}
.law{
        font-size: 15px;
        color: #898585;
        margin-right: 200px;
}
.icons{
    font-size: 30px;
}
.icons a{
    text-decoration: none;
    color: #fff;
}
footer{
    height: 500px;

}
.reserve div a{
    text-decoration: none;
    color: white;
}
.contact-us{
    font-size: 65px;
    margin-left: 80px;
    margin-top: 180px;
    font-weight: 600;
}
.nav-link {
    text-decoration: none;
    color: white;
  }

  .active-link {
    color: red; /* Можно также использовать этот класс для других стилей активной ссылки */
  }

 .care-carousel-container {
    position: relative;
    max-width: 80%;

    margin: 0 auto;
    overflow: hidden;
    margin-top: 80px;

  }
/**/
  .care-carousel-image {
    width: 100%;
    height: auto;
    display: block;

  }

  .care-carousel-navigation {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
  }

  .care-carousel-prev, .care-carousel-next {
    background: none;
    border: none;
    font-size: 2rem;
    color: white;
    cursor: pointer;
    padding: 10px;
  }

  .care-carousel-caption {
    position: absolute;
    bottom: 20px;
    left: 20px;
    display: flex;
    align-items: center;
    gap: 600px; /* Adjust gap to control space between h3 and the button */
  }

  .care-carousel-caption h3 {
    margin-left: 50px;
    font-size: 45px;
    color: white;
  }

  .care-carousel-button {
    text-decoration: none;
    color: white;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 22px;
    background-color: rgba(0, 0, 0, 0.5);
  }
  /* MEDIA QUERY */
/* Styles for small screens like mobile devices */
@media (max-width: 768px) {
    .header {
        flex-direction: column;
        align-items: center;
        padding: 20px 20px;

    }
    .header nav{
        text-align: center;
        align-items: center;
        margin-left: 50px;
    }
    .media{
        font-size: 90px;
    }
    .studio{
        font-size: 70px;
    }

    nav ul {
        display: flex;
        flex: 3;
        margin-left: 0;
        align-items: center;
        text-align: center;
    }

    nav ul li {
        width: 100%; /* Make each menu item take the full width of the container */
        text-align: center;


    }

    .logo {
        margin-left: 0;
        margin-bottom: 20px;
        padding-right: 100px;/* Add space below the logo */
    }
    .about div{
        text-align: center;
        margin-right: 30px;
    }
    .header_text, .header_text1{
        margin-left: 300px;
    }
    .header_text, .header_text1,.header_text2,.header_text3,.header_text4 {
        text-align: center;
        width: 100%;

        margin-top: 20px;
    }

    .background-image {
        margin-left: 0;
        margin-top: 20px;
        width: 100%;
        height: auto; /* Make the image responsive */
    }

    .media-studio {
        font-size: 10px; /* Reduce the font size for smaller screens */
        width: 100%;
        margin-top: 20px;
    }

    .reserve {
        margin-left: 0;
        text-align: center;
        width: 20%;
        margin-top: 40px;
    }

    .reserve .text {
        font-size: 15px; /* Adjust font size for better readability on small screens */
    }

    .line {
        max-width:200px ; /* Adjust the line width */


    }
}


/* Styles for medium screens like tablets */
@media (max-width: 1024px) {
    .header {
        padding: 20px 40px;
    }

    nav ul {
        justify-content: center;
        margin-left: 0;
    }

    .header_text, .header_text1 {
        text-align: center;
        width: 80%;
        margin-left: 0;
    }

    .media-studio {
        font-size: 100px; /* Adjust the font size for tablets */
        width: 100%;
        margin-top: 50px;
    }

    .background-image {
        margin-left: 0;
        margin-top: 30px;
        width: 80%;
        height: auto;
    }

    .reserve {
        margin-left: 0;
        text-align: center;
        width: 100%;
        margin-top: 30px;
    }
}
/* For small screens like mobile devices */
@media (max-width: 768px) {
    .about {
        flex-direction: column;
        margin-left: 10px;
        margin-top: 20px;
    }

    .stat {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        height: auto;

    }

    .stats {
        width: 100%;
        margin-left: 0;
    }

    .about h2 {
        font-size: 40px; /* Adjusted font size for smaller screens */
        margin-bottom: 20px;
    }

    .number {
        font-size: 50px; /* Adjusted font size for numbers */
        margin-top: 20px;
    }

    .stat_text {
        font-size: 12px; /* Adjusted font size for better readability */
        margin-top: 10px;
    }

    .line1 {
        width: 80%;
        margin-top: 20px;
    }
}

/* For medium screens like tablets */
@media (max-width: 1024px) {
    .about {
        margin-left: 40px;
        margin-top: 70px;
    }

    .stat {
        width: 100%;
        justify-content: space-around;
        height: auto;
    }

    .stats {
        width: 100%;
        margin-left: 20px;
    }

    .about h2 {
        font-size: 50px; /* Adjusted font size for tablets */
    }

    .number {
        font-size: 40px; /* Adjusted font size */
        margin-top: 40px;
        margin-right: 30px;
    }

    .stat_text {
        font-size: 13px; /* Adjusted font size */
        margin-top: 20px;
        margin-right: 30px;
    }

    .line1 {
        margin-top: 10px;
    }
}
/* For small screens like mobile devices */
@media (max-width: 768px) {
    .podcast-zone {
        margin: 30px 0;
    }

    .carousel img {
        padding: 0 20px; /* Reduce padding to fit smaller screens */
    }

    .carousel-controls .prev {
        margin-left: 20px; /* Reduce margin for smaller screens */
    }

    .carousel-controls .next {
        margin-right: 20px; /* Reduce margin for smaller screens */
    }

    .carousel-caption h3,
    .carousel-caption-project h3 {
        margin-left: 0; /* Remove left margin to center align the text */
        font-size: 24px; /* Reduce font size for smaller screens */
    }

    .project-book {
        padding: 5px 10px; /* Adjust padding for better fit */
        font-size: 16px; /* Reduce font size for smaller screens */
        margin-left: 10px;
    }
}


/* For medium screens like tablets */
@media (max-width: 1024px) {
    .podcast-zone {
        margin: 40px 0;
    }

    .carousel img {
        padding: 0 40px; /* Adjust padding for tablet screens */
    }

    .carousel-controls .prev {
        margin-left: 50px; /* Adjust margin for tablet screens */
    }

    .carousel-controls .next {
        margin-right: 50px; /* Adjust margin for tablet screens */
    }

    .carousel-caption h3,
    .carousel-caption-project h3 {
        margin-left: 400px; /* Reduce left margin */
        font-size: 32px;
        margin-top: 60px;
/* Adjust font size for tablet screens */
    }

    .project-book {
        padding: 6px 12px; /* Slightly reduce padding */
        font-size: 17px; /* Adjust font size for tablet screens */
        margin-left: 15px;
    }
}
/* For small screens like mobile devices */
@media (max-width: 768px) {
    .projects h2 {
        font-size: 40px; /* Reduce font size for better readability on smaller screens */
        margin-left: 20px; /* Adjust margin for smaller screens */
    }

    .carousel-project img {
        width: 100%; /* Make the image responsive */
        padding: 0 10px; /* Reduce padding for better fit on small screens */
    }

    .carousel-controls .prev {
        margin-left: 20px; /* Adjust margin for smaller screens */
    }

    .carousel-controls .next {
        margin-right: 20px; /* Adjust margin for smaller screens */
    }

    .carousel-caption-project h3 {
        margin-left: 0; /* Center align the text on small screens */
        font-size: 24px; /* Reduce font size */
    }

    .caption-text p {
        font-size: 16px; /* Reduce font size for better readability */
        margin-left: 20px; /* Adjust margin for smaller screens */
    }

    .caption-text h3 {
        font-size: 28px;
    }

    .project-book {
        margin-left: 0; /* Center the button */
        padding: 10px 20px; /* Adjust padding */
        font-size: 16px; /* Reduce font size */
        margin-top: 20px; /* Reduce top margin */
    }

    .podcast-book {
        margin-left: 0; /* Center the button */
        padding: 10px 20px; /* Adjust padding */
        font-size: 16px; /* Reduce font size */
    }
}



/* For medium screens like tablets */
@media (max-width: 1024px) {
    .projects h2 {
        font-size: 50px; /* Adjust font size for tablets */
        margin-left: 40px; /* Adjust margin for tablets */
    }

    .carousel-project img {
        width: 100%; /* Make the image responsive */
        padding: 0 30px; /* Adjust padding for better fit on tablets */
    }

    .carousel-controls .prev {
        margin-left: 50px; /* Adjust margin for tablets */
    }

    .carousel-controls .next {
        margin-right: 50px; /* Adjust margin for tablets */
    }

    .carousel-caption-project h3 {
        margin-left: 50px;
         /* Adjust margin for tablets */
        font-size: 32px; /* Adjust font size */
    }

    .caption-text p {
        font-size: 18px; /* Adjust font size */
        margin-left: 50px; /* Adjust margin */
    }

    .caption-text h3 {
        font-size: 35px; /* Adjust font size */
    }

    .project-book {
        margin-left: 50px; /* Adjust margin */
        padding: 12px 30px; /* Adjust padding */
        font-size: 18px; /* Adjust font size */
        margin-top: 50px; /* Adjust top margin */
    }

    .podcast-book {
        margin-left: 50px; /* Adjust margin */
        padding: 12px 30px; /* Adjust padding */
        font-size: 18px; /* Adjust font size */
    }
}
/* For small screens like mobile devices */
@media (max-width: 768px) {
    .contact h2 {
        font-size: 40px; /* Reduce font size for better readability */
        margin-left: 20px; /* Adjust margin for smaller screens */
    }

    .contact-row {
        flex-direction: column; /* Stack elements vertically */
        align-items: center; /* Center align the elements */
    }

    .container {
        flex-direction: column; /* Stack form and info vertically */
        justify-content: center; /* Center align the container */
        margin-left: 0; /* Remove margin for better alignment */
        padding: 20px; /* Adjust padding for smaller screens */
        max-width: 100%; /* Ensure full-width usage */
    }

    .form-container, .info-container {
        width: 100%; /* Make both containers full-width */
        margin-left: 0; /* Remove margin for better alignment */
        padding-left: 0; /* Remove padding */
    }

    .form-group input, input, textarea {
        width: 100%; /* Make inputs and textarea full-width */
    }

    .contact-info {
        text-align: center; /* Center align the contact info */
        margin-top: 50px;
        display: none; /* Adjust margin for smaller screens */
    }

    .contact-info h2 {
        font-size: 18px; /* Adjust font size for better readability */
    }

    button {
        width: 100%; /* Make the button full-width */
        text-align: center; /* Center align the button text */
    }
}


/* For medium screens like tablets */
@media (max-width: 1024px) {
    .contact h2 {
        font-size: 50px; /* Adjust font size for tablets */
        margin-left: 40px; /* Adjust margin for tablets */
    }

    .contact-row {
        flex-direction: column; /* Stack elements vertically */
        align-items: center; /* Center align the elements */
    }

    .container {
        flex-direction: column; /* Stack form and info vertically */
        justify-content: center; /* Center align the container */
        margin-left: 20px; /* Adjust margin for tablets */
        padding: 30px; /* Adjust padding */
        max-width: 100%; /* Ensure full-width usage */
    }

    .form-container, .info-container {
        width: 100%; /* Make both containers full-width */
        margin-left: 0; /* Remove margin for better alignment */
        padding-left: 0; /* Remove padding */
    }

    .form-group input, input, textarea {
        width: 100%; /* Make inputs and textarea full-width */
    }

    .contact-info {
        text-align: center; /* Center align the contact info */
        margin-top: 70px; /* Adjust margin for tablets */
    }

    .contact-info h2 {
        font-size: 20px; /* Adjust font size for tablets */
    }

    button {
        width: 100%; /* Make the button full-width */
        text-align: center; /* Center align the button text */
    }
}
/* For small screens like mobile devices */
@media (max-width: 768px) {
    .team-book {
        font-size: 20px; /* Reduce font size for better readability on smaller screens */
        text-align: center; /* Center align the text */
    }

    .team-container {
        flex-direction: column; /* Stack team members vertically */
        align-items: center; /* Center align the team members */
        max-width: 100%; /* Ensure the container takes up full width */
    }

    .team-member {
        width: 100%; /* Make each team member take up full width */
        margin-bottom: 20px; /* Maintain the bottom margin */
        text-align: center; /* Center align the content */
    }

    .circular_image {
        width: 100px; /* Reduce the size of the image for mobile screens */
        height: 100px; /* Maintain the aspect ratio */
    }

    .team-member h1 {
        font-size: 1em; /* Adjust the font size */
        margin: 10px 0 5px;
    }

    .team-member p {
        font-size: 0.9em; /* Adjust the font size for better readability */
    }
}

/* For medium screens like tablets */
@media (max-width: 1024px) {
    .team-book {
        font-size: 25px; /* Slightly reduce font size for tablets */
        text-align: center; /* Center align the text */
    }



    .team-member {
        flex-basis: calc(50% - 20px); /* Make each team member take up half the width */
        width: auto; /* Remove fixed width to allow flexibility */
        margin-bottom: 20px; /* Maintain the bottom margin */
        text-align: center; /* Center align the content */
    }

    .circular_image {
        width: 120px; /* Reduce the size of the image for tablets */
        height: 120px; /* Maintain the aspect ratio */
    }

    .team-member h1 {
        font-size: 1.1em; /* Adjust the font size */
        margin: 10px 0 5px;
    }

    .team-member p {
        font-size: 0.95em; /* Adjust the font size for better readability */
    }
}
/* For small screens like mobile devices */
@media (max-width: 768px) {
    .service-header {
        flex-direction: column; /* Stack elements vertically */
        align-items: center; /* Center align the items */
        margin-left: 20px; /* Reduce the left margin */
        margin-right: 20px; /* Reduce the right margin */
        text-align: center; /* Center align the text */
    }

    .service-header h2 {
        font-size: 40px; /* Reduce font size for better readability on smaller screens */
        margin-bottom: 20px; /* Add margin to separate the heading from the button */
    }

    .service-book {
        font-size: 16px; /* Slightly reduce the font size */
        padding: 10px 20px; /* Adjust padding for better fit */
        width: 100%; /* Make the button full-width */
        text-align: center; /* Center align the text */
    }
}

/* For medium screens like tablets */
@media (max-width: 1024px) {
    .service-header {
        margin-left: 40px; /* Adjust the left margin */
        margin-right: 40px; /* Adjust the right margin */
        text-align: center; /* Center align the text */
    }

    .service-header h2 {
        font-size: 50px; /* Reduce font size for tablets */
    }

    .service-book {
        font-size: 18px; /* Maintain font size for tablets */
        padding: 12px 25px; /* Adjust padding for tablets */
        text-align: center; /* Center align the text */
    }
}
/* For small screens like mobile devices */
@media (max-width: 768px) {
    .footer-row {
        flex-direction: column; /* Stack elements vertically */
        margin-left: 20px; /* Reduce left margin */
        margin-right: 20px; /* Reduce right margin */
        text-align: center; /* Center align the text */
    }

    .footer-row div {
        font-size: 30px; /* Reduce font size for better readability */
        width: 100%; /* Make the div take up full width */
        margin-top: 50px; /* Reduce top margin */
    }

    .footer-row ul li a, .footer-row ul li {
        font-size: 18px; /* Slightly reduce font size */
        padding-top: 10px; /* Reduce padding */
    }

    .under-row {
        flex-direction: column; /* Stack elements vertically */
        margin-left: 20px; /* Reduce left margin */
        margin-right: 20px; /* Reduce right margin */
        margin-top: 50px; /* Reduce top margin */
        text-align: center; /* Center align the text */
    }

    .idea, .law, .icons {
        font-size: 14px; /* Slightly reduce font size */
        margin: 10px 0; /* Add margin for spacing */
    }

    .icons {
        font-size: 25px; /* Reduce icon size */
    }

    footer {
        height: auto; /* Allow the footer to adjust its height based on content */
        padding-bottom: 20px; /* Add padding to the bottom */
    }

    .contact-us {
        font-size: 40px; /* Reduce font size */
        margin-left: 20px; /* Adjust left margin */
        margin-top: 100px; /* Adjust top margin */
        text-align: center; /* Center align the text */
    }
}


/* For medium screens like tablets */
@media (max-width: 1024px) {
    .footer-row {
        flex-direction: column; /* Stack elements vertically */
        margin-left: 40px; /* Adjust left margin */
        margin-right: 40px; /* Adjust right margin */
        text-align: center; /* Center align the text */
    }

    .footer-row div {
        font-size: 40px; /* Adjust font size for tablets */
        width: 100%; /* Make the div take up full width */
        margin-top: 75px; /* Adjust top margin */
    }

    .footer-row ul li a, .footer-row ul li {
        font-size: 18px; /* Slightly adjust font size */
        padding-top: 15px; /* Adjust padding */
    }

    .under-row {
        flex-direction: column; /* Stack elements vertically */
        margin-left: 40px; /* Adjust left margin */
        margin-right: 40px; /* Adjust right margin */
        margin-top: 80px; /* Adjust top margin */
        text-align: center; /* Center align the text */
    }

    .idea, .law, .icons {
        font-size: 14px; /* Slightly adjust font size */
        margin: 15px 0; /* Add margin for spacing */
    }

    .icons {
        font-size: 28px; /* Adjust icon size */
    }

    footer {
        height: auto; /* Allow the footer to adjust its height based on content */
        padding-bottom: 30px; /* Add padding to the bottom */
    }

    .contact-us {
        font-size: 50px; /* Adjust font size */
        margin-left: 40px; /* Adjust left margin */
        margin-top: 120px; /* Adjust top margin */
        text-align: center; /* Center align the text */
    }
}
/* For small screens like mobile devices */
@media (max-width: 768px) {
    .media, .studio {
        text-align: center; /* Center align text for better readability on small screens */
        line-height: 1.2; /* Adjust line-height for better spacing */
    }
}


/* For medium screens like tablets */
@media (max-width: 1024px) {
    .media, .studio {
        text-align: center; /* Center align text for a balanced look on tablets */
        line-height: 1.1; /* Slightly adjust line-height */
    }
}
/* For small screens like mobile devices */
@media (max-width: 768px) {
    .media-studio {
        font-size: 60px; /* Reduce font size for better readability on small screens */
        width: 100%; /* Make the element take up full width */
        margin-top: 30px; /* Adjust top margin for better spacing */
        margin-left: 0; /* Remove left margin */
    }
}


/* For medium screens like tablets */
@media (max-width: 1024px) {
    .media-studio {
        font-size: 100px; /* Adjust font size for better readability on tablets */
        width: 100%; /* Make the element take up full width */
        margin-top: 50px; /* Adjust top margin for better spacing */
        margin-left: 0; /* Remove left margin */
    }
}
/* For small screens like mobile devices */
@media (max-width: 768px) {
    .faq_container {
        width: 100%; /* Make the container full width */
        padding: 0 10px; /* Add some padding for better spacing */
    }

    .faq {
        max-width: 100%; /* Make the FAQ section take full width */
        margin-top: 1rem; /* Reduce margin for better spacing */
        padding-bottom: 10px; /* Adjust padding */
    }

    .question {
        flex-direction: column; /* Stack the question text and controls vertically */
        align-items: flex-start; /* Align items to the start */
        text-align: left; /* Align text to the left */
    }

    .question h3 {
        font-size: 1.4rem; /* Reduce font size for better readability */
    }

    .faq-button {
        width: 100%; /* Make the button full width */
        font-size: 16px; /* Reduce font size */
        padding: 12px 8px; /* Adjust padding */
        margin-top: 10px; /* Add margin to separate from the question */
    }

    .answer p {
        font-size: 1.2rem; /* Reduce font size */
        padding-top: 0.5rem; /* Adjust padding for better spacing */
    }

    .line {
        width: 100%; /* Make the line take full width */
        margin: 0 auto; /* Center the line */
    }
}

/* For medium screens like tablets */
@media (max-width: 1024px) {
    .faq_container {
        width: 95%; /* Slightly reduce width for better spacing */
        padding: 0 20px; /* Add some padding for better spacing */
    }

    .faq {
        max-width: 100%; /* Make the FAQ section take full width */
        margin-top: 1.5rem; /* Adjust margin */
        padding-bottom: 10px; /* Adjust padding */
    }

    .question {
        flex-direction: column; /* Stack the question text and controls vertically */
        align-items: flex-start; /* Align items to the start */
        text-align: left; /* Align text to the left */
    }

    .question h3 {
        font-size: 1.6rem; /* Slightly reduce font size */
    }

    .faq-button {
        width: 100%; /* Make the button full width */
        font-size: 17px; /* Adjust font size */
        padding: 14px 9px; /* Adjust padding */
        margin-top: 10px; /* Add margin to separate from the question */
    }

    .answer p {
        font-size: 1.3rem; /* Slightly reduce font size */
        padding-top: 0.75rem; /* Adjust padding */
    }

    .line {
        width: 100%; /* Make the line take full width */
        margin: 0 auto; /* Center the line */
    }
}
/* For small screens like mobile devices */
@media (max-width: 768px) {
    .header_text4 {
        font-size: 14px; /* Reduce font size for better readability on small screens */
        width: 100%; /* Make the element take up full width */
        margin-left: 20px; /* Reduce left margin */
        margin-top: 20px; /* Adjust top margin for better spacing */
    }
}


/* For medium screens like tablets */
@media (max-width: 1024px) {
    .header_text4 {
        font-size: 15px; /* Slightly reduce font size for tablets */
        width: 80%; /* Make the element take up 80% of the width */
        margin-left: 40px; /* Adjust left margin */
        margin-top: 30px; /* Adjust top margin */
    }
}
/* For small screens like mobile devices */
@media (max-width: 768px) {
    .care-carousel-container {
        max-width: 100%; /* Ensure it takes up full width on small screens */
        margin-top: 40px; /* Reduce top margin */
    }

    .care-carousel-caption {
        flex-direction: column; /* Stack elements vertically */
        align-items: flex-start; /* Align items to the start */
        gap: 20px; /* Reduce gap between h3 and the button */
        bottom: 10px; /* Adjust position for better fit on small screens */
        left: 10px; /* Adjust position for better fit on small screens */
    }

    .care-carousel-caption h3 {
        font-size: 24px; /* Reduce font size for better readability */
        margin-left: 0; /* Remove left margin */
    }

    .care-carousel-button {
        font-size: 18px; /* Reduce font size */
        padding: 8px 16px; /* Adjust padding */
        width: 100%; /* Make button full width */
        text-align: center; /* Center align the button text */
    }
}


/* For medium screens like tablets */
@media (max-width: 1024px) {
    .care-carousel-container {
        max-width: 100%; /* Ensure it takes up full width on tablets */
        margin-top: 60px; /* Adjust top margin */
    }

    .care-carousel-caption {
        flex-direction: row; /* Keep elements in a row */
        align-items: center; /* Center align items */
        gap: 40px; /* Reduce gap between h3 and the button */
        bottom: 15px; /* Adjust position for better fit on tablets */
        left: 20px; /* Adjust position for better fit on tablets */
    }

    .care-carousel-caption h3 {
        font-size: 32px; /* Adjust font size for tablets */
        margin-left: 0; /* Remove left margin */
    }

    .care-carousel-button {
        font-size: 20px; /* Adjust font size */
        padding: 10px 18px; /* Adjust padding */
    }
}
/* For small screens like mobile devices */
@media (max-width: 768px) {
    .header_text5 {
        font-size: 14px; /* Reduce font size for better readability on small screens */
        width: 100%; /* Make the element take up full width */
        margin-left: 20px; /* Reduce left margin */
        margin-top: 10px; /* Adjust top margin for better spacing */
    }
}


/* For medium screens like tablets */
@media (max-width: 1024px) {
    .header_text5 {
        font-size: 15px; /* Slightly reduce font size for tablets */
        width: 80%; /* Make the element take up 80% of the width */
        margin-left: 40px; /* Adjust left margin */
        margin-top: 12px; /* Adjust top margin */
    }
}
/* For small screens like mobile devices */
@media (max-width: 768px) {
    .stat {
        flex-direction: column; /* Stack the items vertically */
        align-items: center; /* Center align the items */
        max-width: 300px; /* Make the container full width */
        height: auto; /* Allow height to adjust based on content */
    }
}


/* For medium screens like tablets */
@media (max-width: 1024px) {
    .stat {
        max-width: 300px; /* Make the container full width */
        height: auto; /* Allow height to adjust based on content */
        justify-content: space-around; /* Distribute items evenly */
    }
}
/* For small screens like mobile devices */
@media (max-width: 768px) {
    .carousel-image {
        width: 300px; /* Ensure the image takes up the full width of its container */
        height: auto; /* Maintain the aspect ratio of the image */
        max-height: 300px; /* Set a maximum height to prevent the image from becoming too large */
    }
}


/* For medium screens like tablets */
@media (max-width: 1024px) {
    .carousel-image {
        width: 300px; /* Ensure the image takes up the full width of its container */
        height: auto; /* Maintain the aspect ratio of the image */
        max-height: 500px; /* Set a maximum height suitable for tablet screens */
    }
}
/* For small screens like mobile devices */
@media (max-width: 768px) {
    .projects h2 {
        font-size: 40px; /* Reduce font size for better readability */
        margin-left: 20px; /* Adjust margin for smaller screens */
        text-align: center; /* Center align the title */
    }

    .carousel-project img {
        width: 100%; /* Make the image responsive */
        padding: 0 10px; /* Adjust padding for smaller screens */
    }

    .carousel-controls .prev {
        margin-left: 20px; /* Adjust margin for better visibility */
    }

    .carousel-controls .next {
        margin-right: 20px; /* Adjust margin for better visibility */
    }

    .carousel-caption-project {
        flex-direction: column; /* Stack the elements vertically */
        align-items: flex-start; /* Align items to the start */
        bottom: 10px; /* Adjust position for smaller screens */
        left: 10px; /* Adjust position for smaller screens */
    }

    .carousel-caption-project h3 {
        font-size: 24px; /* Reduce font size for better readability */
        margin-left: 0; /* Remove left margin */
    }

    .caption-text p {
        font-size: 16px; /* Adjust font size for better readability */
        margin-left: 0; /* Remove left margin */
        margin-top: 10px; /* Add margin-top for spacing */
    }

    .project-book {
        margin-left: 0; /* Remove left margin */
        margin-top: 20px; /* Adjust margin for better spacing */
        padding: 10px 20px; /* Adjust padding */
        font-size: 16px; /* Reduce font size */
        width: 100%; /* Make the button full width */
        text-align: center; /* Center align the button */
    }
}


/* For medium screens like tablets */
@media (max-width: 1024px) {
    .projects h2 {
        font-size: 50px; /* Adjust font size for tablets */
        margin-left: 40px; /* Adjust margin for tablets */
        text-align: center; /* Center align the title */
    }

    .carousel-project img {
        width: 100%; /* Make the image responsive */
        padding: 0 20px; /* Adjust padding for tablets */
    }

    .carousel-controls .prev {
        margin-left: 50px; /* Adjust margin for better spacing */
    }

    .carousel-controls .next {
        margin-right: 50px; /* Adjust margin for better spacing */
    }

    .carousel-caption-project {
        flex-direction: row; /* Keep the elements in a row */
        align-items: center; /* Center align items */
        bottom: 15px; /* Adjust position for tablets */
        left: 20px; /* Adjust position for tablets */
    }

    .carousel-caption-project h3 {
        font-size: 32px; /* Adjust font size for tablets */
        margin-left: 0; /* Remove left margin */
    }

    .caption-text p {
        font-size: 18px; /* Adjust font size for better readability */
        margin-left: 0; /* Remove left margin */
        margin-top: 10px; /* Add margin-top for spacing */
    }

    .project-book {
        margin-left: 0; /* Remove left margin */
        margin-top: 30px; /* Adjust margin for better spacing */
        padding: 12px 30px; /* Adjust padding */
        font-size: 18px; /* Adjust font size */
        width: auto; /* Adjust width */
    }
}
/* For small screens like mobile devices */
@media (max-width: 768px) {
    .carousel-controls {
        top: 10%; /* Adjust the position to lower the controls */
        justify-content: space-around; /* Adjust the spacing between the controls */
        padding: 0 20px; /* Add padding to ensure the controls are visible on smaller screens */
    }
}

/* For medium screens like tablets */
@media (max-width: 1024px) {
    .carousel-controls {
        top: 10%; /* Adjust the position slightly lower for tablets */
        justify-content: space-between; /* Keep the controls spaced apart */
        padding: 0 50px; /* Add some padding to ensure controls are easily accessible */
    }
}
@media (min-width: 2500px) {
    .team-container {
        margin-top: 100px;
        gap: 40px;
    }
    .header-content {

        overflow-x: hidden;
        margin-left: 150px;

    }
    .text{
        font-size: 43px;
        margin-top: 30px;
    margin-bottom: 30px;

    }
    .header {
        display: flex;
    }

    body {
        font-size: 18px;
        padding: 0 80px;
    }

    nav ul {
        margin-left: 120px;
        margin-top: 150px;
    }

    nav ul li {
        width: 50%;
        box-sizing: border-box;
    }

    nav ul li a {
        width: 100px;
        font-size: 35px;
        padding-top: 15px;
        padding-bottom: 15px;
        text-align: left;
        font-weight: bold;
        text-decoration: none;
        display: block;
    }

    .header_text, .header_text1 {
        font-size: 25px;
        width: 500px;
        margin-left: 100px;
    }

    .header_text2, .header_text3 {
        margin-top: 100px;
        font-size: 20px;
    }

    .header_text4 {
        margin-top: 60px;
    }

    .header_text5 {
        margin-top: 30px;
    }

    .media-studio {
        font-size: 200px;
        width: 1000px;
        margin-top: 100px;
        margin-left: 40px;
    }

    .background-image {
        margin-left: 35%;
        margin-top: 15%;
        width: 27%;
        height: 25%;
        position: absolute;
        background-image: url('./assets/studio.jpg');
        z-index: -1;

    }

    .media, .studio {
        line-height: 1,2;
    }
}
@media (min-width: 2560px) {
    .logo {
        width: 120px;
        height: 90px;
        margin-left: 100px;
        margin-top: 100px;
    }

    .reserve {
        margin-left: 800px;
        margin-top: 300px;
        font-size: 28px;
    }

    .faq_container {
        width: 90%;
        margin: 0 auto;
        padding: 0;

    }

    .faq {
        max-width: 100%;
        margin-top: 3rem;
        padding-bottom: 20px;
        border-bottom: 3px solid #fff;
    }

    .question_2 {
        max-width: 800px;
    }

    .question h3 {
        font-size: 2.4rem;
    }

    .faq-button{
        width: 300px;
        padding: 20px 0;
        overflow: hidden;
        text-decoration: none;
        opacity: 0;
        transition: opacity 1.4s ease;
        color: white;
        border: 1px solid #fff;
        border-radius: 10px;

        padding: 15px 10px;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
    }
    .faq.active .answer{
        max-height: 650px;
        }
    .answer p {
        font-size: 1.5rem;
        line-height: 2;
    }

    .line {
        width: 500px;

    }

    .about {
        margin-top: 150px;
        margin-left: 120px;
        width: 100%;
    }

    .stat {
        width: 100%;
        height: 200px;
        margin-left: 200px;
    }

    .stats {
        width: 57%;
        margin-left: 150px;
    }

    .about h2 {
        font-size: 100px;
    }

    .number {
        font-size: 100px;
        margin-top: 80px;
    }

    .stat_text {
        font-size: 28px;
        margin-top: 120px;
    }

    .line1 {
        border-top: 2px solid white;
        margin-left: 200px;
    }

    .podcast-zone {
        margin: 80px 0;
    }
}
@media (min-width: 2560px) {
    .carousel img {
        padding: 0 150px;
    }

    .carousel-controls {
        top: 45%;

    }

    .carousel-controls .prev {
        margin-left: 150px;
    }

    .carousel-controls .next {
        margin-right: 10px;
    }

    .carousel-caption h3,
    .carousel-caption-project h3 {
        margin-left: 300px;
        font-size: 60px;
    }

    .project-book,
    .podcast-book
     {
        font-size: 24px;
        padding: 20px 40px;
    }
    .service-book{
        font-size: 40px;
        padding: 30px 60px;
    }
    .podcast-book {
        margin-left: 600px;
    }

    .services {
        margin-top: 120px;
    }

    .service-header {
        margin-left: 120px;
        margin-right: 120px;
    }

    .service-header h2,
    .projects h2 {
        font-size: 150px;

    }

    .carousel-project img {
        width: 100%;
        padding: 0 150px;
    }
    .carousel-controls{
        width: 100%;
    }
    .carousel-caption-project {
        max-width: 80%;
        bottom: 40px;
        left: 40px;
    }
}
@media (min-width: 2560px) {
    .carousel-caption-project h3 {
        margin-left: 200px;
        font-size: 55px;
    }

    .caption-text {
        text-align: left;
    }

    .podcast-text {
        justify-content: space-between;
    }

    .podcast {
        bottom: 20px;
        left: 40px;
    }

    .podcast-text h3 {
        margin-left: 300px;
        font-size: 60px;
    }

    .caption-text p {
        font-size: 26px;
        margin-left: 200px;
    }

    .caption-text h3 {
        font-size: 60px;
    }

    .project-book {
        margin-left: 300px;
        padding: 20px 60px;
        font-size: 28px;
        margin-top: 150px;
    }

    .podcast-book {
        margin-left: 600px;
        font-size: 24px;
    }

    .team-book {
        font-size: 60px;
    }
    .header_text4{
        font-size: 23px;
        margin-left: 130px;
        width: 500px;
        margin-top: 20px;
    }
    .header_text5{
        font-size: 23px;
        margin-left: 130px;
    }
    .team-member {
        flex-basis: calc(33% - 30px);
        width: 500px;
        margin-bottom: 40px;
    }

    .circular_image {
        width: 400px;
        height: 400px;
    }

    .team-member h1 {
        font-size: 40px;
        margin: 30px 0 10px;
    }

    .team-member p {
        font-size: 33px;
    }

    .contact h2 {
        font-size: 80px;
        margin-left: 120px;
    }

    .contact-row {
        justify-content: space-around;
    }

    .contact-section {
        padding: 80px 0;
    }

    .container {
        max-width:100%;
        margin-left: 120px;
        padding: 50px;
    }

    .form-container {
        flex: 2;
    }

    .info-container {
        flex: 1;
        padding-left: 50px;
        margin-left: 80px;
    }

    .info-container .contact-info a {
        margin: 0;
        padding: 0;
    }
}
@media (min-width: 2560px) {
    .info-container a {
        font-size: 40px;
    }

    .form {
        flex-direction: row;
    }

    .form-group label {
        margin-top: 70px;
        font-size: 30px;
    }

    input, textarea {
        width: 1400px;
        font-size: 30px;
    }

    button {
        font-size: 36px;
        padding: 20px 40px;
        margin-top: 60px;
    }

    .contact-info h2 {
        font-size: 40px;
    }

    .contact-info {
        margin-top: 120px;
        font-size: 35px;
        margin-right: 70px;
    }

    .footer-row {
        margin-left: 120px;
        margin-right: 120px;
    }

    .footer-row div {
        font-size: 80px;
        width: 1400px;
        margin-top: 140px;
    }

    .footer-row ul li a, li {
        font-size: 30px;
        padding-top: 30px;
    }

    .under-row {
        margin-left: 120px;
        margin-right: 120px;
        margin-top: 200px;
    }

    .idea,
    .law {
        font-size: 20px;
    }

    .law {
        margin-right: 300px;
    }

    .icons {
        font-size: 40px;
    }

    footer {
        height: 700px;
    }

    .contact-us {
        font-size: 85px;
        margin-left: 120px;
        margin-top: 240px;
    }


}
@media (min-width: 3840px) {
    body{
        font-family: "Montserrat", sans-serif;
    }
    .project-zone {
        margin: 120px 0;
        margin-top: 300px;
        max-width: 100%;
        margin-left: 80px;

    }
    .service-book{
        text-decoration: none;
        color: white;
        border: 1px solid #fff;
        border-radius: 10px;
        padding: 30px 60px;
        font-size: 50px;
        font-weight: 500;
        margin-top: 80px;
    }
    .carousel-project {
        width: 85%;
        height: 1200px;
        margin-left: 150px;
    }

    .carousel-image {
        height: 1200px;
    }

    .carousel-controls {
        top: 40%;
        width: 100%;
    }

    .carousel-controls .prev,
    .carousel-controls .next {
        font-size: 4rem;
        padding: 30px;
    }

    .carousel-caption-project {
        max-width: 100%;
        bottom: 60px;
        left: 60px;
    }

    .caption-text p {
        font-size: 36px;
        width: 80%;
    }

    .caption-text h3 {
        font-size: 80px;
    }

    .project-book {
        margin-left: 600px;
        padding: 30px 80px;
        font-size: 36px;
    }

    .podcast-text {
        justify-content: center;
        text-align: center;
        font-size: 36px;
    }

    .logo {
        width: 200px;
        height: 150px;
        margin-left: 150px;
        margin-top: 120px;
    }

    .header-content {
        overflow-x: hidden;
        margin-left: 200px;
    }
    .line{
        width: 600px;
        margin-left: 300px;


    }

    .about {
        margin-top: 150px;
        margin-left: 150px;
        width: 100%;
    }

    .stat {
        width: 100%;
        height: 200px;
        margin-left: 350px;
    }

    .stats {
        width: 55%;
        margin-left: 150px;
    }
    .number {
        font-size: 150px;
        margin-top: 80px;
    }
    .line1 {
        border-top: 2px solid white;
        margin-left: 350px;
        margin-top: 80px;
    }
    .stat_text {
        font-size: 50px;
        margin-top: 120px;
    }
    .about h2 {
        font-size: 150px;
    }
    .reserve{
        margin-top: 430px;
        margin-left: 1150px;

    }

    .text {
        font-size: 55px;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 290px;
    }

    .header {
        display: flex;
    }

    body {
        font-size: 24px;
        padding: 0 100px;
        font-family: "Montserrat", sans-serif;
    }

    nav ul {
        margin-left: 160px;
        margin-top: 200px;
    }

    nav ul li {
        width: 50%;
        box-sizing: border-box;
    }

    nav ul li a {
        width: 170px;
        font-size: 55px;
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: left;
        font-weight: bold;
        text-decoration: none;
        display: block;
    }

    .header_text {
        font-size: 35px;
        width: 800px;
        margin-left: 150px;
        margin-top: 100px;
    }
    .header_text1{
        font-size: 35px;
        margin-left: 150px;
        margin-top: 50px;
        width: 800px;
    }

    .header_text2 {
        margin-top: 160px;
        font-size: 35px;
        width: 800px;
    }
    .header_text3{
        margin-top: 50px;
        font-size: 35px;
        width: 800px;
    }
    .header_text4 {
        margin-top: 80px;
        font-size: 35px;
        width: 900px;
        margin-left: 170px;
    }

    .header_text5 {
        margin-top: 50px;
        font-size: 35px;
        margin-left: 170px;
        width: 900px;
    }

    .media-studio {
        font-size: 350px;
        width: 1850px;
        margin-top: 150px;
        margin-left: 60px;
    }

    .background-image {
        margin-left: 38%;
        margin-top: 16%;
        width: 30%;
        height: 37%;
        position: absolute;
        background-image: url('./assets/studio.jpg');
        z-index: -1;
    }

    .media, .studio {
        line-height: 1;
    }


    .services {
        margin-top: 180px;
    }

    .service-header {
        margin-left: 160px;
        margin-right: 160px;
    }

    .service-header h2,
    .projects h2 {
        font-size: 180px;

    }
    .service-header h2{
        margin-top: 300px;
    }
    .team-book {
        font-size: 100px;
        margin-top: 100px;
    }

    .team-member {
        flex-basis: calc(33% - 40px);
        width: 600px;
        margin-bottom: 60px;
    }

    .circular_image {
        width: 500px;
        height: 500px;
    }

    .team-member h1 {
        font-size: 55px;
        margin: 40px 0 15px;
    }

    .team-member p {
        font-size: 45px;
    }

    .contact h2 {
        font-size: 100px;
        margin-left: 160px;
    }

    .contact-row {
        justify-content: space-around;
    }

    .contact-section {
        padding: 100px 0;
    }

    .container {
        max-width: 100%;
        margin-left: 160px;
        padding: 70px;
    }

    .form-container {
        flex: 2;
    }

    .info-container {
        flex: 1;
        padding-left: 70px;
        margin-left: 100px;
    }

    .info-container .contact-info a {
        margin: 0;
        padding: 0;
        font-size: 50px;
    }

    .form-group label {
        margin-top: 90px;
        font-size: 40px;
    }

    input, textarea {
        width: 1800px;
        font-size: 40px;
    }

    button {
        font-size: 48px;
        padding: 30px 60px;
        margin-top: 80px;
    }

    .contact-info h2 {
        font-size: 50px;
    }

    .contact-info {
        margin-top: 160px;
        font-size: 50px;

        margin-right: 100px    ;
    }

    .footer-row {
        margin-left: 160px;
        margin-right: 160px;

    }

    .footer-row div {
        font-size: 130px;
        width: 2500px;
        margin-top: 180px;

    }

    .footer-row ul li a, li {
        font-size: 50px;
        padding-top: 60px;
        text-align: right;

    }

    .under-row {
        margin-left: 160px;
        margin-right: 160px;
        margin-top: 250px;
    }

    .idea,
    .law {
        font-size: 30px;
    }

    .law {
        margin-right: 400px;
    }

    .icons {
        font-size: 50px;
    }

    footer {
        height: 900px;
    }

    .contact-us {
        font-size: 110px;
        margin-left: 160px;
        margin-top: 320px;
    }
    .faq_container {

        width: 90%;
        margin: 0 auto;
        padding: 0;

    }
    .answer{
        width: 100%;
    }

    .faq {
        max-width: 100%;
        margin-top: 3rem;
        padding-bottom: 20px;
        border-bottom: 3px solid #fff;
        height: 100%;
        width: 100%;
    }

    .question_2 {
        max-width: 1900px;
    }

    .question h3 {
        font-size: 4.4rem;
    }

    .faq-button {
        width: 500px;
        padding: 25px 50px;
        overflow: hidden;
        text-decoration: none;
        opacity: 0;
        transition: opacity 1.4s ease;
        color: white;
        border: 1px solid #fff;
        border-radius: 10px;
        text-align: center;
        font-size: 50px;
        font-weight: 500;
    }
    .faq.active .answer{
        max-width: 1800px;
        width: 2000px;

        }

    .faq.active .answer p {
        font-size: 2.5rem;
        line-height: 2;
        max-width: 2000px;

        margin: 0;
    }

    .question{
        width: 100%;
    }

    /* .faq.active .answer {
        max-height: 20rem;
    } */



}

